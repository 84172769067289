@font-face {
    font-family: InterUI;
    src: url(./fonts/Inter-Regular.otf);
    font-weight: normal;
}

@font-face {
    font-family: InterUI;
    src: url(./fonts/Inter-Light.otf);
    font-weight: 300;
}
